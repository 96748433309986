<template>
  <div class="dashboard-state">
    <div class="px-3" :class="`dashboard-state-item__color-${currentState.color}`">
      {{ currentState.title }}
    </div>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n';

export default {
  name: 'DocumentActionRequired',
  props: {
    active: {
      type: Boolean,
    },
    contract: {
      type: Object,
      required: true,
    },
  },
  computed: {
    states() {
      return {
        'open.approve': {
          color: 'red',
          title: i18n.t('contract.action_required.open_approve'),
        },
        'open.sign': {
          color: 'red',
          title: i18n.t('contract.action_required.open_sign'),
        },
        'pending.approve': {
          color: 'red',
          title: i18n.t('contract.action_required.approve'),
        },
        'pending.sign': {
          color: 'red',
          title: i18n.t('contract.action_required.sign'),
        },
        completed: {
          color: 'green',
          title: i18n.t('docs.completed'),
        },
        draft: {
          color: 'text',
          title: i18n.t('docs.drafts'),
        },
        review: {
          color: 'yellow',
          title: i18n.t('review.pending_generic'),
        },
        'review.me': {
          color: 'red',
          title: i18n.t('review.pending_me'),
        },
        pending: {
          color: 'yellow',
          title: i18n.t('docs.pending.generic'),
        },
        'pending.me': {
          color: 'red',
          title: i18n.t('docs.pending.me'),
        },
        'pending.expiring': {
          color: 'purple',
          title: i18n.t('docs.pending.expiring'),
        },
        rejected: {
          color: 'text',
          title: i18n.t('docs.rejected'),
        },
        expired: {
          color: 'text',
          title: i18n.t('docs.expired'),
        },
        'completed.expiring': {
          color: 'purple',
          title: i18n.t('docs.completed.expiring'),
        },
        'done.approve': {
          color: 'green',
          title: i18n.t('general.approved'),
        },
        'done.sign': {
          color: 'green',
          title: i18n.t('signing.signed'),
        },
      };
    },
    currentState() {
      let state = this.contract?.state;
      const contractCurrentSignIdentity = this.contract?.current_sign_identity;

      if (
        typeof contractCurrentSignIdentity !== 'undefined' &&
        'approve' === String(contractCurrentSignIdentity?.contract_role)
      ) {
        if (contractCurrentSignIdentity?.is_approved) {
          state = 'completed';
        } else {
          state = this.active ? 'pending.approve' : 'open.approve';
        }
      }

      if (
        typeof contractCurrentSignIdentity !== 'undefined' &&
        'sign' === String(contractCurrentSignIdentity?.contract_role)
      ) {
        if (contractCurrentSignIdentity?.is_signed) {
          state = 'completed';
        } else {
          state = this.active ? 'pending.sign' : 'open.sign';
        }
      }

      if (
        typeof contractCurrentSignIdentity !== 'undefined' &&
        'stamp' === String(contractCurrentSignIdentity?.contract_role)
      ) {
        if (contractCurrentSignIdentity?.is_signed) {
          state = 'completed';
        } else {
          state = this.active ? 'pending.sign' : 'open.sign';
        }
      }

      return this.states[state];
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-state {
  padding-bottom: 10px;
  width: fit-content;
  font-size: small;

  .dashboard-state-item__root {
    padding: 6px;
    border-radius: 6px;
  }

  .dashboard-state-item__color-text {
    @extend .dashboard-state-item__root;
    background-color: #e6e6e6;
    color: #0d0d0d;
  }
  .dashboard-state-item__color-yellow {
    @extend .dashboard-state-item__root;
    background-color: #fdefb5;
    color: #9e772e;
  }
  .dashboard-state-item__color-red {
    @extend .dashboard-state-item__root;
    background-color: #fde2e3;
    color: #d21717;
  }
  .dashboard-state-item__color-purple {
    @extend .dashboard-state-item__root;
    background-color: #f4eaff;
    color: #6329a2;
  }
  .dashboard-state-item__color-green {
    @extend .dashboard-state-item__root;
    background-color: #6decaf;
    color: #0f522f;
  }
}
</style>
