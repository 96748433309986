<template>
  <div>
    <div
      v-for="contract in contractsWithoutActive"
      :key="contract.contract_id"
      class="py-5 contract-side-tabs__tab"
      :class="{'contract-side-tabs__tab-active': !isMobileDevice && contract.contract_id == selectedContractId}"
    >
      <v-row justify="space-between" no-gutters>
        <v-col cols="auto" :offset="isMobileDevice ? 1 : 0" class="pl-2">
          <DocumentActionRequired :contract="contract" :active="_selectedContractId === contract.id" />
        </v-col>

        <v-col cols="auto" class="pl-1">
          <ContractDocumentTabInfo
            :contract-id="contract.id"
            :current-sign-identity="contract.current_sign_identity"
            :sign-identities="contract.sign_identities"
            use-public-fetch
          />
        </v-col>

        <v-col></v-col>

        <v-col class="text-right" order="auto">
          <v-menu transition="slide-y-transition" bottom offset-y style="z-index: 1001 !important;">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-row justify="space-between" no-gutters>
                  <v-col>
                    <span class="contract-side-tabs-client__menu-created">
                      {{ $t('general.created_at') }}:
                    </span>
                  </v-col>

                  <v-col class="text-right">
                    <span class="contract-side-tabs-client__menu-created">
                      {{ $d(contract.created) }}
                    </span>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item class="contract-side-tabs-client__menu--link">
                <IcoDownload />
                <a href="#" @click.prevent="download(contract)">
                  {{ $t('contract.download_pdf') }}
                </a>
              </v-list-item>

              <v-list-item
                class="first-line__dots-menu&#45;&#45;actions-row separately"
                :style="contract.current_user && !contract.current_user.can_reject_this_contract ? `display: none` : ''"
              >
                <IcoRejectRed />
                <a href="#" @click.prevent="$router.push({ name: 'public-sign-reject', params: { contract_id: contract.contract_id, token: $route.params.hash }})">
                  {{ $t('general.decline') }}
                </a>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="mt-0" align="start" justify="start" no-gutters>
        <v-col v-if="isMobileDevice" cols="1">
          <v-btn icon @click="showContractDocument(contract)">
            <v-icon v-if="hasContractShowDocument(contract)" color="primary" large>
              mdi-chevron-up
            </v-icon>
            <v-icon v-else color="primary" large>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="11" class="text-left pl-2">
          <a href="#" class="contract-side-tabs-client__document-link" @click.prevent="showContractDocument(contract)">
            {{ contract.contract_title }}
          </a>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <div v-if="contract.contract_id === canShowContractSlot" class="contract-side-tabs__document">
          <slot />

          <div class="pa-10 pb-3" style="position: fixed; bottom: 1rem; left: 0; z-index: 5; width: 100%">
            <v-btn
              v-if="showSignButton"
              :color="contractPrimaryColor"
              elevation="24"
              block
              dark
              @click="$emit('sign-contract')"
            >
              <v-icon class="pr-2">mdi-check</v-icon>
              {{ $t('contract.sign') }}
            </v-btn>
          </div>
        </div>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { mapActions, mapGetters } from "vuex";
import { ContractHelper } from '@/helpers/ContractHelper'
import { ContractService } from "@/services/ContractService";
import DocumentActionRequired from "@publicSign/components/DocumentActionRequired";
import ContractDocumentTabInfo from "@contract/components/ContractDocumentTabInfo";

export default {
  name: "ContractSideTabsClient",
  components: {
    ContractDocumentTabInfo,
    DocumentActionRequired,
    DocumentState: () => import('@/views/dashboard/components/DocumentState'),
    IcoArrowTop: () => import('@/components/svg/IcoArrowTop'),
    IcoCheck: () => import('@/components/svg/IcoCheck'),
    IcoDownload: () => import('@/components/svg/IcoDownload'),
    IcoRejectRed: () => import('@/components/svg/IcoRejectRed')
  },
  props: {
    mainContractId: {
      required: true
    },
    contracts: {
      required: false,
      default: null
    },
    contractPrimaryColor: {
      type: String,
      default: 'primary'
    },
    selectedContractId: {
      required: false,
      default: null
    },
    showSignButton: {
      type: Boolean,
      default: false
    },
    workspace_id: {
      required: true
    }
  },
  data() {
    return {
      isVisibleSomeDocument: false,
      activeDocument: undefined,
    }
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'window/isMobileDevice'
    }),
    _selectedContractId() {
      return this.selectedContractId || this.mainContractId
    },
    contractsWithoutActive() {
      return this.contracts
    },
    canShowContractSlot() {
      if (this.$vuetify.breakpoint.mobile) {
        return this.activeDocument;
      }

      return undefined;
    }
  },
  mounted() {
    const nextContractId = this.$route.query.contract_id;

    if (nextContractId) {
      this.contracts.forEach((attachment) => {
        const match = attachment.id == nextContractId;

        if (match) {
          this.activeDocument = attachment.id;
        }

        attachment.showDocument = match;
      })
    } else {
      this.contracts[0].showDocument = true;
      this.activeDocument = this.contracts[0].contract_id;
    }
  },
  methods: {
    ...mapActions({
      fetchActiveContract: 'publicContract/fetchActiveContract'
    }),
    arrayBufferToBase64(buffer) {
      let binary = ''
      let bytes = [].slice.call(new Uint8Array(buffer))
      bytes.forEach((b) => binary += String.fromCharCode(b))
      return window.btoa(binary)
    },
    contractChange(contract) {
      if (contract.id === this.selectedContractId) {
        return contract;
      }

      this.fetchActiveContract(contract, this.selectedContractId)
      this.$emit('click', contract)
    },
    download(contract) {
      ContractService
        .getPDFUnauthorized(
          contract.contract_id,
          this.$route.params.hash
        )
        .then((response) => {
          response.arrayBuffer().then((buffer) => {
            const base64Flag = 'data:application/pdf;base64,'
            const imageStr = this.arrayBufferToBase64(buffer)
            FileSaver.saveAs(base64Flag + imageStr, contract.contract_title + '.pdf')
          })
        })
    },
    hasContractShowDocument(contract) {
      return contract.showDocument || false
    },
    isUnsigned(contract) {
      if (ContractHelper.isApprovalRequiredForCurrentUser(contract)) {
        return ContractHelper.isUnsignedOrUnapproved(contract) ? 'pending.approve' : 'done.approve'
      }

      if (ContractHelper.isSignatureRequiredForCurrentUser(contract) && !ContractHelper.isUnsigned(contract)) {
        return 'done.sign'
      }

      return contract.state
    },
    showContractDocument(contract) {
      this.contractChange(contract);
      for (let document of this.contracts) {
        document.showDocument = `${document.contract_id}` === `${contract.contract_id}`
          ? !document.showDocument
          : false
      }

      this.showContractSlot();
    },
    showContractSlot() {
      this.activeDocument = undefined;

      for (let document of this.contracts) {
        if (document.showDocument) {
          this.activeDocument = this.$vuetify.breakpoint.mobile && document.contract_id;
          this.contractChange(document);
        }
      }

      this.$emit('show-signatures', typeof this.activeDocument !== 'undefined');
    },
  }
}
</script>

<style lang="scss" scoped>
.contract-side-tabs {
  &__tab {
    padding: 10px;
    border-bottom: 1px solid #d8d8d8;
    background-color: #ffffff;
    position: relative;

    &-active {
      border-left: 3px solid #5e239e;
      z-index: 100;
    }

    &-active::after {
      content: '';
      position: absolute;
      top: 40px;
      right: -14px;

      border-bottom: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      background-color: #ffffff;
      transform: rotate(-45deg);
      height: 25px;
      width: 25px;
    }

    h2 {
      margin-bottom: 10px;
      cursor: pointer;
    }

    .dashboard-state {
      padding-bottom: 0;
    }

    &--first-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-shrink: 0;

      @include md() {
        align-items: center;
      }

      .first-line__title {
        word-wrap: break-word;
        overflow: hidden;
        margin-top: 4px;
        flex: 90%;

        h2 {
          color: #000000 !important;
        }

        @include md() {
          flex: 80%;
        }
      }

      .first-line__dots {
        flex: 10%;
      }

      .first-line__toggle {
        flex: 10%;
        .first-line__toggle-document svg {
          transform: rotate(180deg);
        }
      }

      .first-line__toggle.active {
        .first-line__toggle-document svg {
          transform: rotate(0deg);
        }
      }

      .first-line__dots-menu {
        position: relative;
        cursor: pointer;
        text-align: right;
      }

      .first-line__dots-menu:after {
        color: #5e239e;
        content: '\2807';
        font-size: 28px;
      }

      .first-line__dots-menu--active {
        position: absolute;
        background: white;
        border-radius: 6px;
        box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.65);
        width: 240px;
        top: 40px;
        right: -12px;
        padding: 15px;
        z-index: 550;
        display: flex;
        flex-direction: column;

        &::after {
          content: '';
          position: absolute;
          top: -5px;
          right: 20px;

          background-color: #ffffff;
          transform: rotate(-45deg);
          height: 10px;
          width: 10px;
        }
      }

      .first-line__dots-menu--actions {
        div {
          display: flex;
          flex-shrink: 0;
          margin: 15px 10px;

          & > a {
            flex: 90%;
            text-align: left;
            padding-left: 10px;
            color: #6fa937;
            font-weight: initial;
            font-size: 15px;
          }

          & > svg {
            position: relative;
            top: -3px;
          }
        }

        &-reject {
          border-top: 1px solid #e0e3e8;
          padding-top: 15px;
        }

        &-delete {
          border-top: 1px solid #e0e3e8;
          padding-top: 15px;

          a {
            color: #7d8086 !important;
          }
        }

        &-row {
          display: flex;
          justify-content: space-between;

          span {
            color: #7d8593;
          }

          span.row__title {
            text-align: left;
            font-size: 14px;
          }
          span.row__data {
            text-align: right;
            font-size: 14px;
          }

          a {
            color: #666b71;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
          }
        }

        &-row.first-link {
          margin-top: 30px;
        }

        &-row.separately {
          border-top: 1px solid #e0e3e8;
          padding-top: 15px;
        }
      }
    }

    &--second-line {}
  }

  &__document {
    padding: -20px !important;
    border-top: 1px solid #d8d8d8;
    margin-top: 20px;
  }
}

.contract-side-tabs-client__menu-created {
  font-size: 14px;
  color: #7d8593;
}

.contract-side-tabs-client__document-link {
  color: var(--v-primary-base) !important;
  word-break: break-word;
}

.contract-side-tabs-client__menu {
  &--link {
    display: flex;
    justify-content: space-between;
    max-width: 220px;

    margin: 15px 10px;

    & > a {
      flex: 90%;
      padding-left: 10px;
      font-weight: initial;
      font-size: 15px !important;
      color: #666b71 !important;
      text-align: left !important;
      line-height: 20px !important;
      word-wrap: break-word;
    }

    & > svg {
      position: relative;
      top: -1px;
    }

    &.separately {
      border-top: 1px solid #e0e3e8;
      padding-top: 15px !important;
    }
  }
}
</style>
